import { BehaviorSubject, Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import { ICandidateProfile } from 'src/app/candidate/models/candidate-profile.model';
import { IRecruiterProfile } from 'src/app/recruiter/models/recruiter-profile.model';
import { IVideoPayload } from '../../models/video-api-payloads/video.model';

@Injectable({
  providedIn: 'root',
})
export class MessagingService {
  private recruiterProfile: BehaviorSubject<IRecruiterProfile | undefined> = new BehaviorSubject<
    IRecruiterProfile | undefined
  >(undefined);

  private candidateProfile: BehaviorSubject<ICandidateProfile | undefined> = new BehaviorSubject<
    ICandidateProfile | undefined
  >(undefined);

  private videos: BehaviorSubject<IVideoPayload[] | undefined> = new BehaviorSubject<
    IVideoPayload[] | undefined
  >(undefined);

  public getCandidateProfile(): Observable<ICandidateProfile | undefined> {
    return this.candidateProfile.asObservable();
  }

  public emitCandidateProfile(profile: ICandidateProfile): void {
    this.candidateProfile.next(profile);
  }

  public getRecruiterProfile(): Observable<IRecruiterProfile | undefined> {
    return this.recruiterProfile.asObservable();
  }

  public emitRecruiterProfile(profile: IRecruiterProfile): void {
    this.recruiterProfile.next(profile);
  }

  public getVideos(): Observable<IVideoPayload[] | undefined> {
    return this.videos.asObservable();
  }

  public emitVideos(videos: IVideoPayload[]): void {
    this.videos.next(videos);
  }
}
